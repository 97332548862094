<template>
  <student-form
    id="cue"
    :title="`DATA ${formTitle}`"
    :is-visible="isVisible"
    @update-triggered="triggerUpdate()"
  >
    <template #goto-prev-form-trigger>
      <button
        class="button --primary uppercase text-white tracking-wider"
        @click="triggerUpdate(previousForm.form)"
      >
        <fa-icon icon="arrow-left"></fa-icon>
        <span class="ml-2">Formulir Sebelumnya</span>
      </button>
    </template>

    <h1 class="text-xl text-center uppercase tracking-wider">{{ title }}</h1>
    <p class="text-center" v-if="selected_form === 'guardian'">
      *) Abaikan jika siswa tidak tinggal dengan Wali
    </p>
    <!-- form -->
    <form class="mb-6" action="#" @submit.prevent="triggerUpdate()">
      <!-- full name -->
      <label class="form-label" for="parent_name">Nama</label>
      <input type="text" id="parent_name" v-model="form.name" />

      <!-- birth year -->
      <label class="form-label" for="parent_birth_year">Tahun lahir</label>
      <input type="text" id="parent_birth_year" v-model="form.birth_year" />

      <!-- last education -->
      <span class="form-label">Pendidikan terakhir</span>
      <div
        class="mb-1"
        v-for="(edu, key) in choices.education"
        :key="`edu-${key}`"
      >
        <input
          :id="'education-' + key"
          type="radio"
          name="education"
          :value="edu"
          v-model="form.last_education"
        />
        <label
          class="ml-2 uppercase tracking-wider"
          :for="'education-' + key"
          >{{ edu }}</label
        >
      </div>

      <!-- job -->
      <span class="form-label">Pekerjaan</span>
      <p class="text-sm text-gray-800">
        * Pilih salah satu yang paling mendekati pekerjaan Anda
      </p>
      <div class="mb-1" v-for="(j, key) in choices.job" :key="`job-${key}`">
        <input
          :id="'job-' + key"
          type="radio"
          name="job"
          :value="j"
          v-model="form.job"
        />
        <label class="ml-2 uppercase tracking-wider" :for="'job-' + key">{{
          j
        }}</label>
      </div>
      <!-- <input type="text" id="job-custom" placeholder="lainnya..." /> -->

      <!-- keterangan pekerjaan -->
      <label class="form-label" for="parent_job_desc">
        Keterangan pekerjaan
      </label>
      <p class="text-sm text-gray-800">
        * Keterangan tambahan secara singkat, misal nama instansi, atau profesi
        Anda secara spesifik.
      </p>
      <input type="text" id="parent_job_desc" v-model="form.job_description" />

      <!-- last education -->
      <span class="form-label">Penghasilan</span>
      <div
        class="mb-1"
        v-for="(sal, key) in choices.salary"
        :key="`salary-${key}`"
      >
        <input
          :id="'salary-' + key"
          type="radio"
          name="salary"
          :value="sal"
          v-model="form.job_salary"
        />
        <label
          class="ml-2 capitalize tracking-wider text-sm"
          :for="'salary-' + key"
          >{{ sal }}</label
        >
      </div>

      <!-- phone -->
      <label class="form-label" for="parent_phone">No. telp. Genggam</label>
      <input type="text" id="parent_phone" v-model="form.phone" />
    </form>
    <template #goto-next-form-trigger>
      <button
        class="button --primary uppercase text-white tracking-wider"
        @click="triggerUpdate(nextForm.form)"
      >
        <span class="mr-2">Formulir Berikutnya</span>
        <fa-icon icon="arrow-right"></fa-icon>
      </button>
    </template>
  </student-form>
</template>

<script>
import _find from "lodash/find";
import _clone from "lodash/clone";
import { mapActions, mapGetters, mapMutations } from "vuex";
import StudentForm from "@/components/Registration/StudentForm.vue";

export default {
  data() {
    return {
      previousForm: {},
      nextForm: {},
      title: "",
      form: {},
      formDefault: {},
      choices: {
        education: [
          "SD/sederajat",
          "SMP/sederajat",
          "SMA/sederajat",
          "D1",
          "D2",
          "D3",
          "D4",
          "S1",
          "S2",
          "S3",
        ],
        job: [
          "Tidak bekerja",
          "Petani",
          "PNS/TNI/Polri",
          "Karyawan Swasta",
          "Pedagang Kecil",
          "Pedagang Besar",
          "Wiraswasta",
          "Wirausaha",
          "Buruh",
          "Pensiunan",
          "TKI",
          "Sudah Meninggal",
          "Lainnya",
        ],
        salary: [
          "Tidak Berpenghasilan",
          "< Rp500.000,00",
          "Rp500.000,00-Rp999.999,00",
          "Rp1.000.000,00-Rp1.999.999,00",
          "Rp2.000.000,00-Rp4.999.999,00",
          "Rp5.000.000,00-Rp20.000.000,00",
          "> Rp20.000.000,00",
        ],
      },
    };
  },
  watch: {
    selected_form(val) {
      // let isParentsExist = item.parents !== undefined || item.parents !== null;
      if (val === null || !this.isVisible) {
        return;
      }
      let parents = this.selected_student.parents;
      let filter = {};
      switch (val) {
        case "father":
          filter = { gender: 1, is_guardian: 0 };
          this.title = "DATA AYAH";
          this.nextForm = { form: "mother", text: "ISI DATA IBU" };
          this.previousForm = { form: "address", text: "ISI DATA ALAMAT" };
          break;
        case "mother":
          filter = { gender: 0, is_guardian: 0 };
          this.title = "DATA IBU";
          this.nextForm = { form: "guardian", text: "ISI DATA WALI" };
          this.previousForm = { form: "father", text: "ISI DATA AYAH" };
          break;
        case "guardian":
          filter = { is_guardian: 1 };
          this.title = "DATA WALI";
          this.nextForm = { form: "core", text: "ISI DATA DIRI" };
          this.previousForm = { form: "mother", text: "ISI DATA IBU" };
          break;
        default:
          this.form = _clone(this.formDefault);
          this.title = "";
          this.previousForm = {};
          this.nextForm = {};
          return;
      }
      this.form = _clone(_find(parents, filter));

      this.$nextTick(() => {
        document.getElementById("cue").scrollTop = 0;
      });
    },
  },
  methods: {
    async triggerUpdate(next = null) {
      await this.updateParent({ item: this.form, next: next });
    },
    ...mapMutations("student", ["SHOW_FORM"]),
    ...mapActions("student", ["updateParent"]),
  },
  computed: {
    isFormTypeValid() {
      let validTypes = ["father", "mother", "guardian"];
      return validTypes.includes(this.selected_form);
    },
    formTitle() {
      let maps = { father: "Ayah", mother: "Ibu", guardian: "wali" };
      return maps[this.selected_form];
    },
    isVisible() {
      return this.form_visibility === true && this.isFormTypeValid;
    },

    ...mapGetters("student", [
      "selected_form",
      "form_visibility",
      "selected_student",
    ]),
  },
  mounted() {
    this.formDefault = _clone(this.form);
  },
  components: {
    StudentForm,
  },
  name: "ParentForm",
};
</script>